import React from "react";
import styles from "../../styles/main.module.css";

function Advert() {
//   const noScriptContent = `
//   <noscript>
//     <style>
//       .no-js-message { display: block; }
//     </style>
//     <div class="no-js-message">
//       <img src="https://bs.serving-sys.com/Serving/adServer.bs?c=8&cn=display&pli=1080269343&gdpr=\${GDPR}&gdpr_consent=\${GDPR_CONSENT_68}&us_privacy=\${US_PRIVACY}&Page=&Pos=1747665938" border=0 width=300 height=250 alt="Advertisement"/>
//     </div>
//   </noscript>
// `;
  return (
    <>
      <div className={`${styles.AdvertStyleMob}`}>
        {/* <div className={`${styles.AdvertStyleThird}`}>
            <a
              href="https://bs.serving-sys.com/Serving/adServer.bs?cn=brd&ns=1&pli=1080269343&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_68}&us_privacy=${US_PRIVACY}&Page=&Pos=1747665938"
              target="_blank"
              className="w100"
            >
              <img
                src="https://bs.serving-sys.com/Serving/adServer.bs?c=8&cn=display&pli=1080269343&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_68}&us_privacy=${US_PRIVACY}&Page=&Pos=1747665938"
                alt="Advertisement"
                className="w-100"
              />
            </a>
            <div dangerouslySetInnerHTML={{ __html: noScriptContent }} />
        </div> */}
        <span className="w-100">
          <div className={`${styles.AdvertStyleTop} d-lg-flex`}></div>
        </span>
        <a
          href="https://www.chaniabank.gr/omilos/eteriki-kinoniki-efthyni/kinonia/digital-school/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-100"
        >
          <div className={`${styles.AdvertStyle} d-lg-flex`}></div>
        </a>

        
      </div>
    </>
  );
}

export default Advert;