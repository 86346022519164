import React ,{ useState, useEffect }from 'react';
import clock from '../../../images/datedark.png'
import { Link } from 'react-router-dom';
import backBtnImg from '../../../images/backbutton.png';
import { tag } from '../HomePageComps/HomePageContainer';
import axios from 'axios';
import { BaseUrl } from '../../../App';
import { useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SideBar from '../../generalComponents/SideBar';
import Container from 'react-bootstrap/Container';
import InsideArticle from '../../generalComponents/InsideArticle';
import adv1 from "../../../images/dei.gif";
import styles from '../../../styles/main.module.css';
import date from '../../../images/datedark.png';
import Socials from '../../generalComponents/Socials';
import facebook from '../../../images/facebookW.png';
import insta from '../../../images/insta.png';
import gmail from '../../../images/gmail.png';
import whatsapp from '../../../images/whatsapp.png';
import { Helmet } from 'react-helmet-async';

const ArticleContainer = (props:any) => {
    const { slug } = useParams();
    const [isLoading,setLoading] = useState(true);
    const [moreArticles,setMoreArticles] = useState([] as any);
    const [hasMoreArticles, setHasMoreArticles] = useState(true);
    const [page, setPage] = useState(1);
    const shareUrl = window.location.href;
    const title = document.title;
    const loadArticles = (pageNum = 1) => {
        axios.get(BaseUrl+'/get-tags-articles',{
            params: {
                tagId: props.material.tagId,
                slug: slug,
                openedId: props.material.id,
                page: pageNum
            }
        }).then((response) => {
            setMoreArticles([...moreArticles, ...response.data.data.articles]);
            setHasMoreArticles(response.data.data.has_more_articles);
            isLoading && setLoading(false);
        })
    };
    const loadMore = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        loadArticles(nextPage);
    };
    useEffect(()=> {
        loadArticles();
    },[props.material.id]);
    if (isLoading) {
        return (<div>Loading...</div>)
    }
    return (
        <Container fluid className="h-100 px-lg-5 py-4 py-lg-5 px-0">
            <Helmet>
                <title>{'The Morning View | ' + props.material.title}</title>
            </Helmet>
            <Container>
                <Row>
                <Col lg={3} >
                    <SideBar {...props} />
                </Col>
                <Col lg={9} xs={12}>
                    <InsideArticle article={props.material} moreArticles={moreArticles} loadMore={loadMore} hasMoreArticles={hasMoreArticles} />
                </Col>
                </Row>
            </Container>
        </Container>
    );
    // return (
    //     <div className={classes.articleContainer}>
    //         <Helmet>
    //             <title>{'The Morning View | ' + props.material.title}</title>
    //         </Helmet>
    //         <div className={classes.blogTagTabs}>
    //             {
    //                 props.tags.map((tag:tag) => {
    //                     return(
    //                         <Link to="/" state={{tagId: tag.id}} key={tag.id}>
    //                             <div key={tag.id}>
    //                                 {tag.description} 
    //                             </div>
    //                         </Link>
    //                     )
    //                 })
    //             }
    //             <div className={classes.advmenubannerdiv}>
    //                 <img className={classes.advmenubanner} src={adv1} alt="" />
    //             </div>
    //         </div>
    //     <div className={classes.articleContainerInner}>
    //         <div className={`${classes.articleImage} ${classes.margTop }`} style={{backgroundImage: "url('"+props.material.imagePath+"')"}}></div>
    //         <div className={`${classes.articleTitle} ${classes.margTop }`}>
    //             {props.material.title}
    //         </div>

    //         <div className={classes.infoWrap}>
    //             <div className={`${classes.articleAuthor} ${classes.margTop }`}>
    //                 {props.material.author.description}
    //             </div>
    //             <div className={`${classes.articleDate}`}>
    //                 <img src={clock}></img><div>{props.material.releaseDate}</div>
    //             </div>
    //         </div>


    //         <div className={`${classes.articleBody} ${classes.margTop }`} dangerouslySetInnerHTML={{__html:props.material.body}}></div>
    //         <div>
    //               <div className="Demo__some-network">
    //                 <FacebookShareButton
    //                   url={shareUrl}
    //                   quote={title}
    //                   className="Demo__some-network__share-button"
    //                 >
    //                   <FacebookIcon size={32} round />
    //                 </FacebookShareButton>
    //               </div>
    //               <div className="Demo__some-network">
    //                 <TwitterShareButton
    //                   url={shareUrl}
    //                   title={title}
    //                   className="Demo__some-network__share-button"
    //                 >
    //                   <TwitterIcon size={32} round />
    //                 </TwitterShareButton>

    //                 <div className="Demo__some-network__share-count">&nbsp;</div>
    //               </div>
    //               <div className="Demo__some-network">
    //                 <ViberShareButton
    //                   url={shareUrl}
    //                   title={title}
    //                   className="Demo__some-network__share-button"
    //                 >
    //                   <ViberIcon size={32} round />
    //                 </ViberShareButton>
    //               </div>
    //               <div className="Demo__some-network">
    //                 <EmailShareButton
    //                     url={shareUrl}
    //                     subject={title}
    //                     body="body"
    //                     className="Demo__some-network__share-button"
    //                 >
    //                     <EmailIcon size={32} round />
    //                 </EmailShareButton>
    //               </div>
    //               <div className="Demo__some-network">
    //                 <InstapaperShareButton
    //                     url={shareUrl}
    //                     title={title}
    //                     className="Demo__some-network__share-button"
    //                 >
    //                     <InstapaperIcon size={32} round />
    //                 </InstapaperShareButton>
    //               </div>
    //         </div>
    //         <div>
    //             <div className={classes.moreText}>Περισσότερα:</div>
    //             <AuthorsMoreArticleList articles={moreArticles} loadMore={loadMore} hasMoreArticles={hasMoreArticles} />
    //         </div>
    //     </div>
        
    //     </div>
    // );
}
export default ArticleContainer;